import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import WithSubnavigation from "./components/Nav";
import LargeWithNewsletter from "./components/Footer";
import UserContextProvider from "./context/UserContext";
import Paths from "./Routes";
import { useLocation } from 'react-router-dom';

export default function App() {
  const location = useLocation()

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'UA-XXXXXXX-X', {
        page_path: location.pathname,
      });
    }
    console.log(location.pathname)
  }, [location]);

  return (
    <UserContextProvider>
      <Box>
        <WithSubnavigation />
        <Box minH="60vh">
          <Paths />
        </Box>
        <LargeWithNewsletter />
      </Box>
    </UserContextProvider>
  );
}
