import React, { useState } from "react";
import {
	Box,
	Button,
	Image,
	Heading,
	Stack,
	Text,
	SimpleGrid,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	useDisclosure,
	Link as ChakraLink,
} from "@chakra-ui/react";
import { getEndpoint } from "../api";
import rowcan from "../assets/rowd.webp";
import rowca from "../assets/rowd2.webp";

export default function LandingPage() {
	// Expanded list of languages for inclusivity
	const languages = [
		{ name: "English", code: "english" },
		{ name: "French", code: "french" },
		{ name: "Mandarin", code: "mandarin" },
		{ name: "Punjabi", code: "punjabi" },
		{ name: "Spanish", code: "spanish" },
		{ name: "Arabic", code: "arabic" },
		{ name: "Filipino", code: "filipino" },
		{ name: "Italian", code: "italian" },
		{ name: "German", code: "german" },
		{ name: "Russian", code: "russian" },
		// Additional languages
		{ name: "Hindi", code: "hindi" },
		{ name: "Japanese", code: "japanese" },
		{ name: "Korean", code: "korean" },
	];

	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [userInfo, setUserInfo] = useState({
		name: "",
		email: "",
		phone: "",
		location: "",
	});
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleLanguageClick = (languageCode) => {
		setSelectedLanguage(languageCode);
		onOpen();
	};

	const handleDownload = () => {
		// Prepare data to send to backend
		const data = {
			language: selectedLanguage,
			...userInfo,
		};

		// Send tracking info to the backend
		const endpoint = getEndpoint("track/download");
		fetch(endpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.catch((error) => console.error("Error tracking download:", error));

		// Proceed to download the file
		window.open(`/rhapsody/${selectedLanguage}.pdf`, "_blank");

		// Reset user info
		setUserInfo({
			name: "",
			email: "",
			phone: "",
			location: "",
		});
		onClose();
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserInfo({ ...userInfo, [name]: value });
	};

	return (
		<>
			{/* Add Christmas-themed decoration */}
			<Box
				backgroundImage="url('/path/to/christmas-decoration.png')"
				backgroundRepeat="no-repeat"
				backgroundSize="cover"
				backgroundPosition="center"
				bgColor="red.50"
			>
				<Box margin={"auto"} textAlign="center" py={5}>
					<Image
						src={rowcan}
						alt="ReachOut World Day Banner"
						py={10}
						mt={10}
						margin={"auto"}
						w={{ base: "90%", md: "50vw" }}
					/>
					<Heading fontSize={{ base: "2xl", md: "4xl" }} pb={5} color="green.700">
						ReachOut World Day with Rhapsody of Realities
					</Heading>
					<Text maxW="800px" margin="auto" pb={5} color="green.900">
						ReachOut World Day is not just a single-day event but a special day
						that launches a month-long global outreach. It's a special time set
						aside to cover the whole world with Rhapsody of Realities in all
						known languages and dialects. Beyond reaching the whole world, over 7
						billion people will be effectively discipled as they're engaged with
						Rhapsody of Realities in an accountable way.
					</Text>
					<Heading fontSize={"2xl"} color="red.600" pb={5}>
						Download Rhapsody of Realities in Your Language
					</Heading>
					<SimpleGrid
						columns={{ base: 1, sm: 2, md: 3 }}
						spacing={5}
						maxW="800px"
						margin="auto"
						pb={5}
					>
						{languages.map((lang, index) => (
							<Button
								key={lang.code}
								colorScheme="green"
								variant="solid"
								onClick={() => handleLanguageClick(lang.code)}
							>
								{lang.name}
							</Button>
						))}
					</SimpleGrid>
				</Box>
			</Box>

			{/* Modal for optional user information */}
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader bgColor="green.600" color="white">
						Optional Information
					</ModalHeader>
					<ModalCloseButton color="white" />
					<ModalBody>
						<Text pb={3}>
							You can provide your contact information below or proceed with the
							download.
						</Text>
						<FormControl id="name" mb={3}>
							<FormLabel>Name</FormLabel>
							<Input
								type="text"
								name="name"
								value={userInfo.name}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl id="email" mb={3}>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								name="email"
								value={userInfo.email}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl id="phone" mb={3}>
							<FormLabel>Phone Number</FormLabel>
							<Input
								type="tel"
								name="phone"
								value={userInfo.phone}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl id="location" mb={3}>
							<FormLabel>Location</FormLabel>
							<Input
								type="text"
								name="location"
								value={userInfo.location}
								onChange={handleChange}
							/>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="green" mr={3} onClick={handleDownload}>
							Download
						</Button>
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* Adjusted spacing and updated testimonies section */}
			<Box bg="red.50" py={10} mt={10}>
				<Box w={"80%"} m="auto" textAlign={"center"}>
					<Heading fontSize={"2xl"} color="green.700" pb={5}>
						Amazing Testimonies Everywhere! 😊
					</Heading>
					<Text pb={3} color="green.900">
						Click below to see/hear beautiful testimonies from the Rhapsody Impact.
					</Text>
					<Button
						as="a"
						href="https://abilliontestimoniesandmore.org/"
						target="_blank"
						rel="noopener noreferrer"
						colorScheme="red"
						mb={5}
					>
						Visit Testimonies Website
					</Button>
				</Box>
			</Box>

			{/* Increase spacing between image and text */}
			<Image
				src={rowca}
				alt="ReachOut World Campaigns"
				margin={"auto"}
				w={{ base: "90%", md: "50vw" }}
				mt={10}
				mb={10}
			/>

			{/* Other Languages Section with click-to-email button */}
			<Box bg="green.50" py={10}>
				<Box w={"80%"} m="auto" textAlign={"center"}>
					<Heading fontSize={"2xl"} color="red.600" pb={5}>
						Want Rhapsody in a Different Language?
					</Heading>
					<Text pb={3} color="green.900">
						Rhapsody of Realities is available in all known languages and
						dialects! Let us know which language you want your copy in.
					</Text>
					<Button
						as="a"
						href="mailto:contact@lwcanada.org?subject=Rhapsody%20Language%20Request"
						colorScheme="green"
						mb={3}
					>
						Email Us for Your Language
					</Button>
					<Text>Email: contact@lwcanada.org</Text>
					<Text>Instagram: @blwcan.subregion</Text>
				</Box>
			</Box>
		</>
	);
}
